<template>
  <div>

    <div v-loading="loading" class="add_wrap">

      <div class="right font-size-small">
        <!-- main:justify -->
        <!-- <avue-form ref="avue-form" v-model="form" style="margin-top:12px" :option="searchOption" @submit="handleSearchSubmit">
          <template slot="menuForm" slot-scope="scope">
            <el-button @click="handleSearchReset">清空</el-button>
          </template>
        </avue-form> -->
        <div>
          <ul flex="" style="" class="video_wrap">
            <li v-for="(item, i) in videoList" :key="i" style="margin-bottom:20px;width:25%;box-sizing: border-box;">
              <!-- <video src=""></video> -->
              <div v-if="isVideo(item.url)" style="padding:0 10px;">
                <video :ref="'video' + i" class="video_box" :src="item.url" :controls="true" />
              </div>
              <div v-else style="padding:0 10px;">
                <!-- <video :ref="'video'+i" class="video_box" :src="item.url" :controls="true" /> -->
                <img width="100%" height="200" :src="item.url" alt="" srcset="">
              </div>
              <p flex="cross:center">
                <span class="dot" />
                <span class="line" />
              </p>
              <div>
                <p flex="cross:center"><span class="small_dot" /> 拍摄人：{{ item.person || '--' }}</p>
                <!-- <p flex="cross:center"><span class="small_dot" /> 类型：{{ item.$category || '--' }}</p> -->
                <p v-if="currentRow.dictId == '13'" flex="cross:center"><span class="small_dot" /> 拍摄设备：{{
                  item.device || '--' }}</p>
                <p v-if="currentRow.dictId == '13'" flex="cross:center"><span class="small_dot" /> 视频时长：{{
                  videoDuration[i] + 's' || '--' }}</p>
                <p flex="cross:center"><span class="small_dot" /> 文件格式：{{ videoType(item.url) || '--' }}</p>
                <p flex="cross:center"><span class="small_dot" /> 时间：{{ parseTime(item.time,
                  '{y}年{m}月{d}日{h}时{i}分') || '--' }}</p>
                <p v-if="currentRow.dictId == '11'" flex="cross:center"><span class="small_dot" /> 备注：{{ item.remark
                  || '--' }}</p>
                <div v-if="currentRow.dictId == '13'">
                  <el-button v-if="item.$tag" style="margin-left:26px" size="mini" plain type="success">{{ item.$tag
                  }}</el-button>
                  <el-button style="margin-left:10px" size="mini" plain type="warning"
                    @click="openDrawerDialog(item)">分享</el-button>
                </div>
              </div>
            </li>
            <div v-if="!videoList.length" flex="dir:top cross:center main:justify" style="width:100%">
              <img src="@/assets/empty-image-error.png" height="200" description="">
              <div class="font-size-mini" flex="main:center">暂无视频</div>
            </div>
            <!-- <div class="line-cross" /> -->
          </ul>
        </div>
      </div>
    </div>
    <div class="footer" flex>
      <el-button class="flex_1" style="line-height:40px" @click="handleCancel">返回</el-button>
    </div>
    <el-image v-show="false" ref="elImage" :preview-src-list="srcList" />
    <el-drawer title="我是标题" :visible.sync="drawerVisible" :with-header="false">
      <avue-form v-model="drawerForm" style="margin-top:12px" :option="drawerOption" @submit="drawerSubmit">
        <template slot="endTime" slot-scope="{}">
          <el-date-picker v-model="drawerForm.endTime" value-format="yyyy-MM-dd" align="right" type="date"
            placeholder="选择日期" :picker-options="pickerOptions" @change="drawerDateChange" />
          <p v-if="totalDays > 0 && drawerForm.endTime">距分享结束还有{{ totalDays }}天</p>
        </template>
      </avue-form>
    </el-drawer>
    <el-dialog title="提示" :visible.sync="shareDialog" class="share-dialog avue-dialog avue-dialog--top" width="40%">
      <avue-form v-model="shareForm" style="margin-top:12px" :option="shareOption" />
      <div class="avue-dialog__footer">
        <el-button type="primary" @click="copyText">复制链接及提取码</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import DataFlow from '@/mixins/DataFlow'
import { getVideoList } from '@/api/video'
export default {
  mixins: [DataFlow],
  provide() {
    return {
      videoSrcObj: this.videoSrcObj
    }
  },
  data() {
    return {
      srcList: [],
      videoSrcObj: {},
      form: {},
      videoList: [],
      videoDuration: [],
      drawerVisible: false,
      drawerForm: {},
      drawerItem: {},
      totalDays: 0,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        }
      },
      shareDialog: false,
      shareForm: {}
    }
  },
  computed: {
    uploadUrl() {
      return this.baseUrl + '/common/upload'
    },
    isMobile() {
      return window.screen.width <= 540
    },
    videoType() {
      return (url) => {
        const arr = url.split('.')
        const type = arr[arr.length - 1]
        return type
      }
    },
    searchOption() {
      return {
        emptyBtn: false,
        menuSpan: 6,
        labelWidth: 80,
        column: [
          {
            label: '姓名',
            prop: 'name',
            span: 6
          },
          {
            label: '视频分类',
            prop: 'category',
            type: 'select',
            dicUrl: `/system/dict/data/type/video_category?isDic=true`,
            props: {
              label: 'dictLabel',
              value: 'dictValue'
            },
            dicHeaders: this.headers,
            span: 6
          },
          {
            label: '标签',
            prop: 'tag',
            type: 'select',
            dicUrl: `/system/dict/data/type/tag_type?isDic=true`,
            props: {
              label: 'dictLabel',
              value: 'dictValue'
            },
            dicHeaders: this.headers,
            span: 6
          }
        ]
      }
    },
    drawerOption() {
      return {
        column: [
          {
            label: '转发次数',
            prop: 'count1',
            type: 'number',
            span: 24,
            rules: [{
              required: true,
              message: '请输入转发次数',
              trigger: 'change'
            }]
          },
          {
            label: '可播次数',
            prop: 'count',
            type: 'number',
            span: 24,
            rules: [{
              required: true,
              message: '请输入可播次数',
              trigger: 'change'
            }]
          },
          {
            label: '到期时间',
            prop: 'endTime',
            type: 'date',
            span: 24,
            rules: [{
              required: true,
              message: '请选择到期时间',
              trigger: 'blur'
            }]
          }
        ]
      }
    },
    shareOption() {
      return {
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            detail: true,
            label: '私密链接',
            prop: 'href',
            span: 24
          },
          {
            detail: true,
            label: '提取码',
            prop: 'pass',
            span: 24
          }
        ]
      }
    }
  },
  methods: {
    async beforeHandleData(params) {
      let res = await getVideoList({ noticeId: params.noticeId })
      let videoList = res.data
      this.videoList = videoList
      this._videoList = JSON.parse(JSON.stringify(videoList))
      this.$nextTick(() => {
        this.videoList.forEach((item, i) => {
          if (this.isVideo(item.url)) {
            const domI = this.$refs['video' + i][0]
            domI.addEventListener('loadedmetadata', () => {
              this.$set(this.videoDuration, i, this.$refs['video' + i][0].duration)
            })
          }
        })
      })
    },
    handleSearchReset() {
      this.form.name = ''
      this.form.tag = ''
      this.form.category = ''
      this.handleSearchSubmit(this.form)
    },
    handleSearchSubmit(form, callback) {
      const { name, tag, category } = form
      const filterArr = this._videoList.filter(item => {
        const form = item.form
        return form.person.includes(name) && form.category.includes(tag) && form.category.includes(category)
      })
      this.videoList = JSON.parse(JSON.stringify(filterArr))

      callback && callback()
    },
    openDrawerDialog(item) {
      this.drawerVisible = true
      this.drawerItem = item
    },
    drawerDateChange(e) {
      if (e) {
        const diffDate = Math.abs(new Date().getTime() - new Date(e).getTime())
        const diffDay = diffDate / (1000 * 3600 * 24)
        const totalDays = Math.ceil(diffDay)
        this.totalDays = totalDays
      }
    },
    drawerSubmit(form, done) {
      const href = `${location.origin}/share/${Math.random().toString(36).slice(2)}`
      const data = {
        ...form,
        url: this.drawerItem.url,
        href
      }
      fetch(`${this.baseUrl}/archives/share`, {
        method: 'POST',
        headers: { ...this.headers, 'Content-Type': 'application/json;charset=UTF-8' },
        mode: 'cors',
        body: JSON.stringify(data)
      }).then(e => e.json()).then(res => {
        if (res.code == 200) {
          this.drawerVisible = false
          this.shareDialog = true
          this.shareForm.pass = res.msg
          this.shareForm.href = href
        }
        done()
      }).catch(e => {
        done()
      })
    },
    copyText() {
      const { href, pass } = this.shareForm
      this.$Clipboard({
        text: `链接: ${href} 提取码: ${pass}`
      }).then(() => {
        this.$message.success('复制成功')
      }).catch(() => {
        this.$message.error('复制失败')
      })
    },
    async getDetailByNoticeId() {
      return await fetch(`${this.baseUrl}/archives/notice/${this.$route.query.noticeId}`, {
        method: 'GET',
        headers: this.headers
      }).then((e) => e.json())
    },
    async submitForm() {
      this.loading = true
      let { jsonStr } = this.currentRow
      jsonStr = jsonStr && jsonStr != '' ? JSON.parse(jsonStr) : []
      this.formData.createTime = new Date().getTime()
      if (this.currentIndex) {
        this.currentRow.handleType = 2
        jsonStr.splice(this.currentIndex, 1, this.formData)
      } else {
        this.currentRow.handleType = 1
        jsonStr.push(this.formData)
      }
      // this.currentIndex ? jsonStr.splice(this.currentIndex, 1, this.formData) : jsonStr.push(this.formData)
      this.currentRow.jsonStr = JSON.stringify(jsonStr),
        fetch(`${this.baseUrl}/archives/notice`, {
          method: 'PUT',
          headers: { ...this.headers, 'Content-Type': 'application/json;charset=UTF-8' },
          mode: 'cors',
          body: JSON.stringify(this.currentRow)
        }).then(e => e.json()).then(res => {
          if (res.code == 200) {
            // alert(res.msg)
            this.loading = false
            this.backUniApp('success')
            if (this.isMobile) {
              // this.$message.success('操作成功')
              alert('操作成功')
            }
            window.parent.postMessage('success', '*')
          }
        }).catch(e => {
          this.loading = false
          window.parent.postMessage('error', '*')
        })
    },
    handleCancel() {
      this.backUniApp('cancel')
      window.parent.postMessage('cancel', '*')
      // window.history.go(-1)
    }

  }
}
</script>

<style scoped lang="scss">
.add_wrap {
  .left {
    padding-bottom: 20px;
    padding-left: 12px;
    height: calc(100vh - 66px);
    overflow: auto;
  }

  .right {
    flex: 1;
    height: calc(100vh - 66px);
    overflow: auto;
    padding: 0 12px;
    min-width: 50%;

    .video_wrap {
      padding: 0;
      flex-wrap: wrap;
      position: relative;

      .video_box {
        height: 200px;
        width: 100%;
        box-shadow: 1px 1px 10px #ddd;
      }

      .dot {
        width: 15px;
        height: 15px;
        background: #3196FA;
        border-radius: 50%;
        border: 2px solid #CDE6FF;
      }

      .line {
        height: 1px;
        flex: 1;
        background: #3196FA
      }

      .line-cross {
        height: 402px;
        width: 1px;
        position: absolute;
        top: 226px;
        background: #3196FA;
      }

      .small_dot {
        width: 6px;
        border-radius: 50%;
        height: 6px;
        margin-right: 15px;
        background: #3196FA;
      }
    }
  }
}

::v-deep .share-dialog {
  .el-form-item {
    background: #fff;
  }
}
</style>
